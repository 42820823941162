import styled from 'styled-components';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2%;
`;

export const Issues = styled.div`
  height: 100%;
  padding: 0 5px;
`;


